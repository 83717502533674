import dayjs from "dayjs";
import isBlank from "src/utils/isBlank";
import isString from "src/utils/isString";

/**
 * Format the given date as an internationalized, human-readable string
 */
export function formatDate(
  isoDate?: string | dayjs.Dayjs | null,
  locale = window.Localize?.getLanguage().toString() ?? "default"
): {
  full: () => string;
  short: () => string;
} {
  return {
    full: () => {
      if (isBlank(isoDate)) return "";
      const date = dayjs(isoDate);

      if (date.isValid()) {
        return new Intl.DateTimeFormat(locale, {
          dateStyle: "long",
        }).format(date.toDate());
      }

      return "";
    },

    short: () => {
      if (isBlank(isoDate)) return "";

      // Support masked dates, which wouldn't be considered valid above
      if (isString(isoDate) && isoDate.includes("*")) {
        const [year, month, day] = isoDate
          .split("-")
          // Remove leading zeros
          .map((datePart) => datePart.replace(/^0/, ""));
        return `${month}/${day}/${year}`;
      }

      const date = dayjs(isoDate);
      if (date.isValid()) {
        return new Intl.DateTimeFormat(locale).format(date.toDate());
      }

      return "";
    },
  };
}

export function formatDateFull(
  date?: string | dayjs.Dayjs | null,
  locale?: string
): string {
  return formatDate(date, locale).full();
}

export function formatDateString(
  isoDate?: string | dayjs.Dayjs | null,
  locale?: string
): string {
  return formatDate(isoDate, locale).short();
}

export default formatDate;

import {
  BenefitsApplicationDocument,
  ClaimDocument,
  UserDocument,
} from "../models/Document";

import DownloadableDocument from "./DownloadableDocument";
import Icon from "./core/Icon";
import PaginatedList from "./PaginatedList";
import React from "react";

export interface DocumentListProps {
  documents: Array<BenefitsApplicationDocument | UserDocument | ClaimDocument>;
  downloadBenefitsApplicationDocument?: (
    document: BenefitsApplicationDocument
  ) => Promise<Blob | undefined>;
  downloadUserDocument?: (
    user_id: string,
    document: UserDocument
  ) => Promise<Blob | undefined>;
  showCreatedAt?: boolean;
  useDisplayName?: boolean;
  userId?: string;
  addPagination?: boolean;
}

const PAGE_SIZE = 7;

/**
 * Document list and content
 */
const DocumentList = (props: DocumentListProps) => {
  const {
    documents,
    useDisplayName,
    downloadBenefitsApplicationDocument,
    downloadUserDocument,
    showCreatedAt,
    userId,
    addPagination,
  } = props;

  if (!documents.length) return null;

  const documentList = documents.map((document) => (
    <li
      className="grid-row flex-row flex-justify-start flex-align-start margin-bottom-1 text-primary"
      key={document.fineos_document_id}
    >
      <Icon
        className="margin-right-1"
        fill="currentColor"
        name="file_present"
        size={3}
      />
      <div>
        <DownloadableDocument
          document={document}
          downloadBenefitsApplicationDocument={
            downloadBenefitsApplicationDocument
          }
          downloadUserDocument={downloadUserDocument}
          showCreatedAt={showCreatedAt}
          displayDocumentName={
            useDisplayName ? document.name ?? undefined : undefined
          }
          userId={userId}
        />
      </div>
    </li>
  ));

  if (addPagination) {
    return (
      <PaginatedList pageSize={PAGE_SIZE} sortedListItems={documentList} />
    );
  } else {
    return <ul className="add-list-reset">{documentList}</ul>;
  }
};

DocumentList.defaultProps = {
  addPagination: false,
};

export default DocumentList;
